@charset "utf-8";
// Core variables and mixins
@import "base/variables.scss";
@import "base/mixins.scss";

// Custom colors
$primary: #799a19 !default;
$white: #fff !default;
$black: #000 !default;
$body-bg: #000 !default;
$body-color: #fff !default;

// Custom Images
$header-image: "../img/header.jpg";
$contact-image: "../img/contact.png";

// Rotation
/* https://css-tricks.com/books/greatest-css-tricks/scroll-animation/ */
/* https://flaviocopes.com/rotate-image/ */
.rotate {
    animation: rotate 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
    animation-iteration-count: 1;
    animation-fill-mode: both;
}
@keyframes rotate {
    to {
      transform: rotate(360deg);
    }
}

// Carousel Indicators
.carousel-indicators li {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    display: none;
    /*background-color: $primary;*/
}
@media(min-width:768px) {
    .carousel-indicators li {
        display: inline;
    }
}

/* // Carousel Controls
.carousel-control-next, .carousel-control-prev {
    color: $primary;
    text-align: center;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23799a19' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23799a19' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}
*/

// Global CSS
@import "base/page.scss";

// Components
@import "components/buttons.scss";
@import "components/navbar.scss";
@import "components/client-scroll.scss";

// Layout
@import "layout/masthead.scss";
@import "layout/services.scss";
@import "layout/portfolio.scss";
@import "layout/timeline.scss";
@import "layout/team.scss";
@import "layout/contact.scss";
@import "layout/footer.scss";
