// Styling for the contact section
section#contact {
  background-color: $gray-900;
  background-image: url("#{$contact-image}");
  height: 750px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  .section-heading {
    color: $white;
  }
  .form-group {
    margin-bottom: 25px;
    input,
    textarea {
      padding: 20px;
    }
    input.form-control {
      height: auto;
    }
    textarea.form-control {
      height: 248px;
    }
  }
  .form-control:focus {
    border-color: $primary;
    box-shadow: none;
  }
  ::-webkit-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  :-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  ::-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
  :-ms-input-placeholder {
    font-weight: 700;
    color: $gray-400;
    @include heading-font;
  }
}
@media(max-width:768px) {
  section#contact {
    height: inherit;
    background-attachment: scroll;
  }
}